<template>
  <!-- prettier-ignore -->
  <the-typography type="bodyLargeShort">
    <div
      class="toggle"
      :class="{'toggle--readonly': $props.readonly}"
    >
      <template v-if="$props.placement === 'left'">
        <the-typography
          tag="span"
          type="bodyLargeShort"
          :for="uid"
        >
          <slot>
            {{ $props.titleLabel }}
          </slot>
        </the-typography>
      </template>

      <div>
        <input
          :id="uid"
          class="toggle__input"
          type="checkbox"
          :checked="$props.modelValue"
          :false-value="false"
          :name="$props.name"
          :true-value="true"
          :value="$props.modelValue"
          @change="emitUpdate"
        />
        <label
          class="toggle__label"
          :for="uid"
        >
          <the-icon
            class="toggle__icon"
            art="solid"
            name="check"
          />
        </label>
      </div>

      <template v-if="$props.placement === 'right'">
        <the-typography
          tag="span"
          type="bodyLargeShort"
          :for="uid"
        >
          <slot>
            {{ $props.titleLabel }}
          </slot>
        </the-typography>
      </template>
    </div>
  </the-typography>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid'

// INIT
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  name: {
    type: String,
    required: true,
  },
  placement: {
    type: String,
    default: 'right',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  titleLabel: {
    type: String,
    default: '',
  },
  validate: {
    type: Object,
    default: () => {},
  },
})

// DATA
const uid = uuidv4()

// METHODS
function emitUpdate() {
  props.validate?.$touch()
  emit('update:modelValue', !props.modelValue)
}
</script>

<style name="mobile" scoped>
.toggle {
  --toggle-animation: cubic-bezier(0, 0, 1, 1);

  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  .toggle__input {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + label {
      background: var(--c-primary-color-2);
    }

    &:checked + .toggle__label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
    &:checked + .toggle__label .toggle__icon {
      left: calc(100% - 18px);
      opacity: 1;
    }
  }

  .toggle__label {
    cursor: pointer;
    text-indent: -9999px;
    width: 48px;
    height: 24px;
    background: var(--c-secondary-neutral-3);
    display: block;
    border-radius: 100px;
    position: relative;

    .toggle__icon {
      position: absolute;
      top: 0;
      left: 5px;
      bottom: 0;
      margin: auto 0;
      height: 14px;
      z-index: 1;
      opacity: 0;
      font-size: 14px;
      color: var(--c-primary-color-2);
      transition:
        left 0.15s var(--toggle-animation),
        opacity 0.15s var(--toggle-animation);
    }

    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      background: var(--c-primary-neutral-3);
      border-radius: 90px;
      transform: translateX(0);
      transition:
        transform 0.15s var(--toggle-animation),
        left 0.15s var(--toggle-animation);
    }
  }

  &--readonly {
    pointer-events: none;
  }
}
</style>
